// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPriceTierSimpelist, addUserGroups } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.next'),
      setupActive: 0,
      isNext: false,
      sites: [],
      groupTypeOptions: [
        {
          label: this.$t('form.groupUser.retail'),
          value: 'retail'
        },
        {
          label: this.$t('form.groupUser.wholesale'),
          value: 'wholesale'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPriceTierSimpelist().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[2].options = res.data.wholesale_pricing_tiers
          this.formData[0].createDateItem[1].options = this.groupTypeOptions
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      const params = {
        user_group: obj
      }
      addUserGroups(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            this.setupActive = 1
            this.$router.push({
              name: 'userGroupMembersAdd',
              query: {
                user_group_id: res.data.user_group.id,
                user_type: obj.group_type
              }
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    }
  }
}
